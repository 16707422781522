// admin ww 4g8sLz823!L*7eGu4T
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueSignature from "vue-signature-pad";

if(process.env.VUE_APP_FRONT_END=='fvdl'){
  require('./scss/fvdl.scss');
}
if(process.env.VUE_APP_FRONT_END=='zzpr'){
  require('./scss/zzpr.scss');
}
if(process.env.VUE_APP_FRONT_END=='ffdemo'){
  require('./scss/ffdemo.scss');
}
if(process.env.VUE_APP_FRONT_END=='ffintern'){
  require('./scss/ffintern.scss');
}
if(process.env.VUE_APP_FRONT_END=='vddungen'){
  require('./scss/vddungen.scss');
}
if(process.env.VUE_APP_FRONT_END=='heywin'){
  require('./scss/heywin.scss');
}
if(process.env.VUE_APP_FRONT_END=='vanoort'){
  require('./scss/vanoort.scss');
}
if(process.env.VUE_APP_FRONT_END=='tsb'){
  require('./scss/tsb.scss');
}
if(process.env.VUE_APP_FRONT_END=='snel'){
  require('./scss/snel.scss');
}
if(process.env.VUE_APP_FRONT_END=='dakcontrol'){
  require('./scss/dakcontrol.scss');
}
require('./scss/general.scss');
require('./scss/ffwax.scss');

import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,  
  BarElement,
} from 'chart.js'
Chart.register(
  LineController,
  LineElement,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip, 
  Legend, 
)

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import 'vue-select/dist/vue-select.css';

import i18n from '@/plugins/i18n';
import '@/mixins/generalMixin';
import '@/mixins/modalMixin';
import '@/mixins/appStylingMixin';
import '@/mixins/holidaysMixin';
import '@/mixins/planningMixin';
import '@/mixins/overwriteMixin';

import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(CKEditor)
Vue.use(VueSignature)

//SET API SETTINGS
/*
store.commit('api_protocol', 'https://')
// store.commit('api_protocol', 'http://')
store.commit('api_host', 'zzpr.flyingkiwi.nl')
// store.commit('api_host', 'localhost')
store.commit('api_port', '12346')
store.commit('api_heartbeat', '10000')
   */
//SET AXIOS DEFAULTS
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';


Vue.filter('yesno', function (value) {
  return value ? 'Ja' : 'Nee';
})
  
//CHECK IF STILL LOGGED IN
axios.interceptors.request.use(
    //success handler
    function (config) {
      store.commit('loading', true)
      return config;
    }, 
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  )
  axios.interceptors.response.use(
    //success handler
    function (response) {
      store.commit('loading', false)
      return response;
    }, 
    
    //error handler
    function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          // store.commit('current_user', null)
          // store.dispatch('get_current_user')
        }
        else 
        {
          console.log('ERROR')
        }
      }
  
      // Do something with response error
      return Promise.reject(error);
    }
  )

 //START VUE
new Vue({
    store,
    router,
    i18n, 
    render: h => h(App),
  }).$mount('#app')


  