<template>
    <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
      <h1> Standaard instellingen</h1>
      <span>Hier kunnen enkele instellingen aangepast worden. Dit overschrijft de standaard instellingen zoals de portal is ingesteld. Sommige instellingen kunnen per gebruiker of opdrachtgever ingesteld worden. Die overschrijven nu de instellingen die hier gedaan zijn weer.</span>
      <hr>
      <h2>Contract instellingen</h2>
      <div class="form-row" >
      <div class="col-3" v-if="settings.RegisterWorkhours">
        {{ $t("labels.workhours") }}
      </div>
      <div class="col-2" v-if="settings.RegisterWorkhours">
        <b-form-group>
          <b-form-radio v-model="workhours" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="workhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2"  v-if="settings.SignWorkhours">
        {{ $t("labels.signworkhours") }}
      </div>
      <div class="col-2" v-if="settings.SignWorkhours">
        <b-form-group>
          <b-form-radio v-model="signworkhours" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="signworkhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
    </div>

    <div class="form-row" >
      <div class="col-3">
        {{ $t("labels.notificationprojectstart") }} 
      </div>
      <div class="col-2" >
        <b-form-group>
          <b-form-radio v-model="startnotification" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="startnotification" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2" >
        {{ $t("labels.notificationendworktime") }}
      </div>
      <div class="col-2" >
        <b-form-group>
          <b-form-radio v-model="sendnotification" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="sendnotification" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
    </div>

    <div class="form-row" >
      <div class="col-3" >
        Tekst aanvang werktijd
      </div>
      <div class="col-2">
        <b-form-input type=text class="form-control" v-model="startdatenote" />
      </div>
      <div class="col-3" >
        Tekst einde werktijd
      </div>
      <div class="col-2">
        <b-form-input type=text class="form-control" v-model="enddatenote" />
      </div>      
    </div>
    <hr>
    <h2>Project instellingen</h2>
    <div class="form-row" >
      <div class="col-3" >
      Starttijd
      </div>
      <div class="col-2">
        <b-form-input type=time class="form-control" v-model="projectstarttime" />
      </div>
      <div class="col-3" >
        Eindtijd
      </div>
      <div class="col-2">
        <b-form-input type=time class="form-control" v-model="projectendtime" />
      </div>      
    </div>
    <div class="form-row" v-if="settings.EnableHourPlanning || specialRights('URSchedule')">
            <div class="col-3" >
              <label>{{ $t('meta.planningcolor') }}</label>
            </div>
            <div class="col">
              <input type="color" v-model="projectplanningscolor"/>
            </div>
          </div> 
    <!-- Save button -->
        <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savesettings") }}
    </div>     
    </b-container>

</template>
<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'projectzzper-planning',
  data() {
    return {
      projectstarttime:null,
      projectendtime:null,
      projectplanningscolor:null,
      startdate: null,
      startdatenote: "",
      enddate: null,
      enddatenote: "",
      planning_id: 0,
      project_reference_number: "",
      project_description: "",
      project_location: "",
      project_name: "",
      sign_id: null,
      copy_id: null,
      contact_id: null,
      contract_id: null,
      pointcontact_id: null,
      urencodering: null,
      urencoderingextended: null,
      invoiceperhourcode:null,
      hourcategory: 0,
      hourregistration: "app",
      inkoopfactuur: null,
      mailurenzzpr: "",
      mailurencontact: "",
      payment_zzprnl_zzpr: null,
      payment_client_zzpr: null,
      payment_client_zzprnl: null,
      tarief: null,
      verkooptarief: null,
      marge: null,
      betaalsysteem: null,
      btw_verlegd: null,
      found_planning_id: -1,
      kleur:"#ff0000",
      declaraties: [],
      extrainfoclient: "",
      extrainfozzpr: "",
      extrainfoinvoice: "",
      registerdeclarations: null,
      registernormalhours:'yes',
      contract_color: null,
      label1:"",
      label2:"",
      signworkhours:"",
      workhours:"",
      startnotification: "no",
      sendnotification:"no",
      starttime:null,
      endtime:null
  
    }
  },

  mounted() {
    this.onMounted()
  },
  computed: {
    ...mapGetters([
      'zzpers'
      , 'createAny',

    ]),
    ...mapState([
      'settings',
    ]),  

  },
  methods: {
    onMounted() {
      this.workhours = this.getOverWrite("workhours")
      this.signworkhours = this.getOverWrite("signworkhours")
      this.startdatenote = this.getOverWrite("startdatenote")
      this.enddatenote = this.getOverWrite("enddatenote")
      this.startnotification = this.getOverWrite("startnotification")
      this.sendnotification = this.getOverWrite("sendnotification")
      this.projectstarttime = this.getOverWrite("projectstarttime")
      this.projectendtime = this.getOverWrite("projectendtime")
      this.projectplanningscolor= this.getOverWrite("projectplanningscolor")
      // this.inkoopfactuur = inkoopfactuur
    },

    save_settings() {
      try {
        let DefaultOverwrite={}
        DefaultOverwrite.signworkhours = this.signworkhours
        DefaultOverwrite.workhours = this.workhours
        DefaultOverwrite.startdatenote = this.startdatenote
        DefaultOverwrite.enddatenote = this.enddatenote
        DefaultOverwrite.startnotification = this.startnotification
        DefaultOverwrite.sendnotification = this.sendnotification
        DefaultOverwrite.projectstarttime = this.projectstarttime
        DefaultOverwrite.projectendtime = this.projectendtime
        DefaultOverwrite.projectplanningscolor = this.projectplanningscolor
        axios.patch('/api/settings/',
          {
            DefaultOverwrite: JSON.stringify(DefaultOverwrite),
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },
  watch: {
  },


}
</script>
