<template>
  <b-container>

    <!-- Title bar -->
    <div class="title-bar">
      <h1> {{ $t("labels.settings") }}</h1>
    </div>
    <button v-if="current_user.id == '1'" @click="test()">klik</button>
    <b-row>
      <b-col>
        <h2>{{ $t("meta.general") }}</h2>
        <b-button-group vertical>
          <b-button active-class="active" v-if="createAny('hourcodes')" to="/hourcategories">
            <font-awesome-icon icon="stopwatch" /> {{ $t("titles.hourcodes") }}
          </b-button>
          <b-button active-class="active" v-if="createAny('branche')" to="/branches">
            <font-awesome-icon icon="code-branch" /> {{ $t("titles.branches") }}
          </b-button>
          <b-button active-class="active" v-if="current_user.role == 'admin' && createAny('tags')" to="/tags">
            <font-awesome-icon icon="tags" /> {{ $t("titles.tags") }}
          </b-button>
          <b-button active-class="active" v-if="current_user.role == 'admin' && createAny('tags')" to="/declaraties">
            <font-awesome-icon icon="euro-sign" /> {{ $t("titles.declaraties") }}
          </b-button>

          <b-button active-class="active" v-if="current_user.role == 'admin'" to="/biccodes">
            <font-awesome-icon icon="euro-sign" /> {{ $t("labels.BIC") }}
          </b-button>           
          <b-button active-class="active" v-if="current_user.id == '1' && createAny('tags')" to="/superadmins">
            <font-awesome-icon icon="brain" />Set SuperAdmins
          </b-button>          
          <b-button active-class="active" v-if="current_user.id == '1' && createAny('tags')" to="/license">
            <font-awesome-icon icon="money-bill" />License
          </b-button>  
          <b-button active-class="active" v-if="current_user.id == '1' && createAny('tags')" to="/metas">
            <font-awesome-icon icon="megaphone" />Metas
          </b-button>   
          <b-button active-class="active" v-if="current_user.id == '1' && createAny('tags')" @click="refreshlatlong()">
            <font-awesome-icon icon="globe" />Refresh Lat Long
          </b-button>              
                 
        </b-button-group>
        <hr>
        {{$t('labels.loadhoursfrom') }} 
        <input type="number" v-model="weekstoload">
        {{$t('labels.loadhoursto') }}
      </b-col>
      <b-col>
        <h2 v-if="currentUserIsSuperAdmin() || hasSpecialRights()">{{ $t("meta.administratie") }}</h2>
        <b-button-group vertical>
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/documents">
            <font-awesome-icon icon="file" /> {{ $t("titles.documents") }}
          </b-button>   
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/notifications">
            <font-awesome-icon icon="exclamation" /> {{ $t("labels.usernotification") }}
          </b-button>                        
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/settings">
            <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailtemplates") }}
          </b-button>
          <b-button active-class="active" v-if="(currentUserIsSuperAdmin() || specialRights('URsenderemail'))&& current_user.role == 'admin'" to="/emailsenders">
            <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailsenders") }}
          </b-button>
          <b-button active-class="active" v-if="this.settings.EnableNewsletter==true && (currentUserIsSuperAdmin() || specialRights('URnewsletter')) && current_user.role == 'admin'" to="/newsletter">
            <font-awesome-icon icon="envelope" /> {{ $t("titles.newsletter") }}
          </b-button>
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/smssettings">
            <font-awesome-icon icon="sms" /> {{ $t("buttons.smssettings") }}
          </b-button>          
          <b-button active-class="active" v-if="current_user.id == '1' &&  current_user.role == 'admin'" to="/finance">
            <font-awesome-icon icon="euro-sign" /> {{ $t("buttons.financesettings") }}
          </b-button>
          <b-button active-class="active" v-if="current_user.id == '1' &&  current_user.role == 'admin'" to="/defaultsettings">
            <font-awesome-icon icon="euro-sign" /> Standaard instellingen
          </b-button>
          
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin' && settings.useCarousel" to="/carouselsettings">
            <font-awesome-icon icon="puzzle-piece" /> {{ $t("titles.carousel") }}
          </b-button>   
          <b-button active-class="active" v-if="settings.EnableUsageInvoices==true && currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/usage">
            <font-awesome-icon icon="euro-sign" /> {{ $t("buttons.portalusage") }}
          </b-button>          
          
 
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && current_user.role == 'admin'" to="/modules">
            <font-awesome-icon icon="puzzle-piece" /> {{ $t("buttons.modules") }}
          </b-button>                
          <b-button active-class="active" v-if="(currentUserIsSuperAdmin() || specialRights('URapppages')) && current_user.role == 'admin'" to="/apppages">
            <font-awesome-icon icon="mobile" /> {{ $t("buttons.appsettings") }}
          </b-button>
          <b-button active-class="active" v-if="current_user.id == '1' && current_user.role == 'admin'" to="/cronsettings">
            <font-awesome-icon icon="spider" /> {{ $t("buttons.cronsettings") }}
          </b-button>   
          <b-button active-class="active" v-if="(currentUserIsSuperAdmin() || specialRights('URdashboard')) && current_user.role == 'admin'" to="/dashboard">
            <font-awesome-icon icon="euro-sign" /> {{ $t("titles.dashboard") }} 
          </b-button>                   
          <b-button active-class="active" v-if="currentUserIsSuperAdmin() && createAny('users')" to="/ffinvoice">
            <font-awesome-icon icon="euro-sign" />Invoice info
          </b-button>             
        </b-button-group>
      </b-col>
    </b-row>

    <br>
  
    <!-- <div v-if="createAny('project')" @click="css()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />css
    </div> -->
    <!-- <div v-if="createAny('project')" @click="sendWhatsapp()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />whatsapp
    </div> -->
     <!-- <h1> IMPORT ZZPers </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportZZPR()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importusers
    </div> -->
    <!-- 
    <h1> IMPORT users </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportUsers()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importusers
    </div> -->

    <!-- <h1> IMPORT companies </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportCompany()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importcompany
    </div> -->
    <div>

    </div>
    </b-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import send_whatsapp_modal from './modals/SendWhatsappModal'
import axios from 'axios'

export default {
  name: 'navigation',
  data() {
    return {
      weekval:35,
      contract_id:796,
      yearval:2023,
      importdata: "",
      weekstoload:null,
      authurl:"",
      portals:[],
      selectedportal:null,
      invoice:null
    }
  },
async mounted() {
      if(this.weekshistory) {
          this.weekstoload = this.weekshistory
      }
    await fetch(`${'https://flyingfreelancers.nl/php/portals.php'}`)
      .then((response) => response.json())
      .then((data) => this.portals = data)      
    },
    watch: {
      weekstoload: function () {
        this.$store.commit('weekshistory', this.weekstoload);
      }
    },  
  computed: {
    ...mapGetters([
      'createAny',
      'project_by_id',
      'carouselToken'
    ]),
    ...mapState([
      'current_user',
      'users',
      'companies',
      'weekshistory',
      'pms',
      'contracts',
      'workfields'

    ]),

  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },

    ...mapActions([
      'add_user',
      'add_company',
      'add_notification',
      'add_factuur',
      'add_pm',
      'save_user',
      'generate_contract_company'
    ]),

    codeArrived(code) {
      console.log(code);
    },
    parseimportZZPR() {
      // disable reroute in add_user function in the store when using the import script
      let lines = this.importdata.split(/\r?\n/);
      let count = 0
      lines.forEach(person => {
        let fields = person.split(/\t/)
        let user = {}
        count++
        user.id = 0
        user.role = "user"
        user.branche_id = 1
        user.firstname = fields[1] ? fields[1].replace(/ /g, '') : ""
        user.middlename = fields[2]
        user.lastname = fields[3] ? fields[3].replace(/ /g, '') : ""
        if (fields[0] == "m") {
          user.sex = "man"
        } else {
          user.sex = "vrouw"
        }
        let adressparts = fields[4].split(/ /)

        user.housenumber = adressparts.pop()
        user.adres = adressparts.join(" ")
        user.email =fields[8]
        user.postcode = fields[5]
        user.city = fields[6]
        user.birthday = fields[7].split('-').reverse().join("-")

        user.username = fields[1].replace(/ /g, '') + fields[2].replace(/ /g, '') + fields[3].replace(/ /g, '') + count
        user.password = fields[1] + "12345"
        user.workfields = []
        user.mobile = fields[9]
        user.phone = fields[9]
        user.signupdate = "2023-10-19"
        if(this.users.filter(u=>u.email== user.email).length!=0){
          console.log("not added ",user.firstname, user.middlename, user.lastname, user.email)
        } else {
          this.add_user(user)
        }


      });
    },
    parseimportUsers() {
      let lines = this.importdata.split(/\r?\n/);
      let count = 0
      lines.forEach(person => {
        let fields = person.split(/\t/)
        count++
        let user = {}
        user.id = 0
        user.role = "contactpersoon"
        user.branche_id = 1
        user.firstname = fields[1].replace(/ /g, '')
        user.middlename = fields[2]
        user.lastname = fields[3].replace(/ /g, '')
        if (fields[0] == "m") {
          user.sex = "man"
        } else {
          user.sex = "vrouw"
        }
        user.mobile = fields[6]
        user.email = fields[5]
        user.username = fields[1].replace(/ /g, '') + fields[2].replace(/ /g, '') + fields[3].replace(/ /g, '') + count
        user.password = fields[1] + "12345"
        user.workfields = []
        console.log(user)

        this.add_user(user)


      });
    },
    parseimportCompany() {
      let lines = this.importdata.split(/\r?\n/);
      lines.forEach(person => {
        let fields = person.split(/\t/)
        let company = {}
        company.id = 0
        company.company_name = fields[0]
        company.email = "info@zzpr.nl"
        company.owner_id = 7
        company.branche_id = 1
        company.postcode = fields[2]
        let adressparts = fields[1].split(/ /)

        console.log(adressparts)
        company.housenumber = adressparts.pop()
        company.adres = adressparts.join(" ")
        company.city = fields[3]
        company.kvkcode = fields[4]


        console.log(company)

        this.add_company(company)


      });
    },
    async test (){
      // let data =  {"body":"don't give up","title":"We zoeken mensen MET EEN HELE LANGE TITEL voor "+ Math.random(),"id": Math.random(),
      // "data":{"project_id":38,"contract_id":42, 'week':12,'year':2023, "token":"89u4328"}}
      // console.log(data)      
      // this.add_notification(data)
      
      // axios.get('/api/pms/all')
      // .then((response) => {
      //     console.log('pms', response.data)
      //   })
      //   .catch(() => {
      //   });
      // var newFact = { id: 0, week: 27, contract_id: 41, user_id: 3 }
      // console.log(newFact);

      // this.add_factuur(newFact)
      // var newPM = { id: 0, to: this.current_user.id, from: 3, message:'Help me do this!' }
      // this.add_pm(newPM)
    //   axios.post('/api/usage_invoice',{
    //     invoice_id:1,
    //     user_id:997, 
    //     force:true,
    //     week:32,
    //     numberofweeks:4,
    //     omschrijving: "gebruik Flying Freelancers Portal"
    // } )
      // this.users.filter(u=>u.role=='contactpersoon').forEach(u =>{
      //   let has_company = false
      //   this.companies.forEach(c => {
      //     if(c.contactpersons.indexOf(u.id)!=-1){
      //       has_company = true
      //       // console.log(this.username_by_id(u.id), c.company_name)

      //     }
      //   })
      //   if(!has_company){
      //     console.log(this.username_by_id(u.id))
      //   }
      // })
      // for await(const u of this.users){
      //   u.mail =1
      //   u.sms =1
      //   u.inapp=1
      //   console.log("start save", u.id)
      //   await axios.put("/api/user/" + u.id, u).then(() => {

      //       console.log("saved user response ",u.id)
      //   })


      // }
          //     await axios.get('/api/snelstart_get_relatie',  { params: {
          //   id: 559,
          // }})
          //     .then((response) => {
          //         console.log('snelstart_get_relaties', response.data)
          //       })
          //       .catch(() => {
          //       });
        // for await(const c of this.contracts){

        //   console.log(c.id)
        //   await this.generate_contract_company(c.id)
        // }
      // generate_company_pdf
    
      //await axios.get("/api/finqledebtors")
      await axios.get("/api/get_hoursbooked_last12months")
      .then(function (response) {
        console.log(response);

      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async refreshlatlong(){
      for(var i=0;i<this.users.length;i++){

        console.log(this.users[i].postcode, this.users[i].housenumber)
        if(this.users[i].postcode!=undefined && this.users[i].housenumber!=undefined){
        await fetch(
                  "https://api.pro6pp.nl/v2/autocomplete/nl?authKey=1u9iXssOsxr8wRR5&postalCode=" + this.users[i].postcode + "&streetNumberAndPremise="+this.users[i].housenumber,
                  {
                      method: "GET",
                  }
              ).then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
                  //   throw new Error('Something went wrong');
              })
              .then(async (data) => {
                  this.users[i].adres = data.street
                  this.users[i].city = data.settlement
                  this.users[i].province = data.province
                  this.users[i].lat = data.lat
                  this.users[i].lng = data.lng
                  this.users[i].latlongaddress = this.users[i].adres + " " + this.users[i].housenumber + " " + this.users[i].city + " " + this.users[i].country

                  await this.save_user(this.users[i].id)
              })
                .catch((error) => {
                    console.log(error)
                });
        }
      }

    },
    sendWhatsapp() {
      this.$modal.hide('send_whatsapp_modal')
      this.$modal.show(send_whatsapp_modal, {
      }, {
        name: 'send_whatsapp_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    },
    boekhoud() {
      // var crediteur_data = {}
      // crediteur_data.debnum = "001"
      // axios.get('/api/get_crediteur', crediteur_data)
      this.users.forEach(user => {
        if(user.debnum) {
          console.log(user.debnum + "," + user.iban+ "," + user.bic+ "," + user.companyname)
        }
        
      });
    },
    async generateInvoice(){
      console.log(this.selectedportal.url)
      axios.post("/api/ff_invoice/", { portal:this.selectedportal, invoiceCount:this.invoice})
       .then(() => {
         let routeUrl = this.server_url + '/media_invoices/ffinvoice.pdf'
          window.open(routeUrl, '_blank')
       })

    },
   findStyle(selector, newstyle) {
      for (var i=document.styleSheets.length; i-->0;) {
        for (var j = document.styleSheets[i].cssRules.length; j-->0;) {
          var r = document.styleSheets[i].cssRules[j];
          // console.log(r)
          if (r.selectorText==selector) {
            r.cssText = newstyle
            return r;
          }
        }
      }
    }  ,  
    css() {
      console.log(this.findStyle('.btn-secondary'), "color:#000; background-color:#ffffff")   
      let elements = document.getElementsByClassName('.btn');
      console.log(elements)
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.backgroundColor="yellow";
    }      
    }

  },
}
</script>